import { useAcceptFriendRequestMutation } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useMe } from 'hooks/useMe';
import { usePathname } from 'next/navigation';
import { FriendRequestPayload } from '../types/friendRequestPayload';
import {
  addUserToFriendsInCache,
  removeFriendRequestFromCache,
  setUserAsFriendInCache,
} from './useAcceptFriendRequest.helper';
import { updateFriendRequestAmountInCache } from 'hooks/useAmountOfFriendRequests';

export const useAcceptFriendRequest = () => {
  const [acceptFriendRequestFunc] = useAcceptFriendRequestMutation();
  const { me } = useMe();
  const dive = useDive();
  const pathname = usePathname() ?? '';

  return (user: FriendRequestPayload) => {
    dive.trackUserFriendRequestAccepted(user.username, pathname);
    return acceptFriendRequestFunc({
      variables: {
        targetId: parseInt(user.id, 10),
      },
      optimisticResponse: {
        __typename: 'Mutation',
        acceptFriendRequest: {
          __typename: 'UserFriend',
          friendSince: new Date(),
          user: {
            id: user.id,
            username: user.username,
            pictureUrl: user.pictureUrl,
            __typename: 'User',
            isFriends: true,
            level: user.level,
            isVerified: user.isVerified,
            isEmployee: user.isEmployee,
            haveISentFriendRequest: false,
            haveIReceivedFriendRequest: false,
          },
        },
      },
      update(store, { data }) {
        setUserAsFriendInCache(store, user.id);

        if (me) {
          removeFriendRequestFromCache(store, data?.acceptFriendRequest?.user?.id ?? '', me.id);

          addUserToFriendsInCache(store, user, me.id);

          updateFriendRequestAmountInCache(store);
        }
      },
    });
  };
};
