import { ApolloCache } from '@apollo/client';
import { FriendRequestsQuery, UserByUsernameQuery, UserFriendsQuery } from 'generated/graphql';
import { FriendRequestPayload } from '../types/friendRequestPayload';

export const setUserAsFriendInCache = (store: ApolloCache<UserByUsernameQuery>, userId: string | number) => {
  try {
    store.modify({
      id: `User:${userId}`,
      fields: {
        isFriends: () => {
          return true;
        },
        haveISendFriendRequest: () => {
          return false;
        },
        haveIReceivedFriendRequest: () => {
          return false;
        },
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const addUserToFriendsInCache = (
  store: ApolloCache<UserFriendsQuery>,
  newFriend: FriendRequestPayload,
  userId: string | number
) => {
  try {
    store.modify({
      id: `User:${userId}`,
      fields: {
        totalFriends: existing => existing + 1,
        getFriends: existing => {
          return {
            ...existing,
            edges: [
              {
                __typename: 'UserFriendEdge',
                node: {
                  friendSince: new Date(),
                  __typename: 'UserFriend',
                  user: {
                    __typename: 'User',
                    isFriends: true,
                    ...newFriend,
                  },
                },
              },
              ...existing.edges,
            ],
          };
        },
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const removeFriendRequestFromCache = (
  store: ApolloCache<FriendRequestsQuery>,
  senderId: string,
  meId: string
) => {
  try {
    store.modify({
      id: `User:${meId}`,
      fields: {
        getFriendInvites: (existing, { readField }) => existing.filter(node => readField('id', node) != senderId),
      },
    });
  } catch (error) {
    console.error(error);
  }
};
