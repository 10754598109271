import styled, { css } from 'styled-components';
import { LabelWrapperStyleProps } from './FriendButton.types';

const fontCSS = css`
  font-size: 1rem;
  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
`;

export const FeatureFriendButton = styled.button`
  border: none;
  color: ${({ theme }) => theme.colors.button.primary.default.text};
  ${fontCSS};
  width: 40px;
  padding-right: 1px;
  background-color: ${({ theme }) => theme.colors.button.primary.default.background};
  transition: all 0.35s ease;
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:not(.haveIFeaturedFriend):not(.haveReachedMaxAmount):hover {
    background-color: ${({ theme }) => theme.colors.button.primary.hover.background};
  }

  &.haveReachedMaxAmount {
    cursor: default;
  }

  @media screen and (min-width: 450px) {
    &.haveIFeaturedFriend:hover,
    &.isHoveringFriendsButton {
      border-left: 1px solid #891a1a;
      background-color: ${({ theme }) => theme.primaryColor.red};
    }
  }

  :hover span {
    visibility: visible;
  }

  &.haveReachedMaxAmount:not(.haveIFeaturedFriend) {
    & > i {
      color: #16956c;
    }
  }

  @media screen and (max-width: 450px) {
    width: 33px;
  }
`;

export const FriendButtonWrapper = styled.div`
  display: flex;
  width: 150px;
  height: 44px;
  ${fontCSS};
  border-radius: 8px;
  @media screen and (max-width: 450px) {
    width: 120px;
  }
`;

export const LabelWrapper = styled.span<LabelWrapperStyleProps>`
  position: absolute;
  width: 120px;
  margin-top: ${({ size }) => -130 - size}px;
  margin-left: ${({ size }) => size / 2}px;
`;

export const Label = styled.span`
  position: absolute;
  display: block;
  width: 120px;
  visibility: hidden;
  text-transform: none;
  text-align: center;
  font-size: 11px;
  background: ${({ theme }) => theme.primaryColor.appDarkGray};
  border-radius: 8px;
  padding: 12px 10px;
  z-index: 11;
  user-select: none;
  white-space: normal;

  :before {
    content: ' ';
    position: absolute;
    top: 100%;
    right: 58px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.primaryColor.appDarkGray} transparent transparent transparent;
  }
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const MobileLabel = styled.span`
  position: absolute;
  display: block;
  width: 120px;
  visibility: hidden;
  text-transform: none;
  text-align: center;
  font-size: 11px;
  background: ${({ theme }) => theme.primaryColor.appDarkGray};
  border-radius: 8px;
  padding: 12px 10px;
  z-index: 11;
  user-select: none;
  white-space: normal;

  :before {
    content: ' ';
    position: absolute;
    top: 100%;
    right: 58px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.primaryColor.appDarkGray} transparent transparent transparent;
  }
  @media screen and (min-width: 450px) {
    display: none;
  }
`;
