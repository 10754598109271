import { Checkmark, Friends } from '@hiberworld/icons';
import { Link } from 'components/common/Link';
import { Button } from 'components/common/buttons/Button';
import { FriendRequestButton } from 'components/common/buttons/FriendButton';
import { FriendRequestAction } from 'components/common/buttons/FriendButton/FriendButton.types';
import { VerifiedBadge } from 'components/globals/VerifiedBadge';
import { Spacer } from 'components/layouts/Spacer';
import { User } from 'generated/graphql';
import { FriendRequestPayload } from 'hooks/friends/types/friendRequestPayload';
import { useAcceptFriendRequest } from 'hooks/friends/useAcceptFriendRequest';
import { useCancelFriendRequest } from 'hooks/friends/useCancelFriendRequest';
import { useSendFriendRequest } from 'hooks/friends/useSendFriendRequest';
import { useMe } from 'hooks/useMe';
import dynamic from 'next/dynamic';
import { useTheme } from 'styled-components';

const RelativeDate = dynamic(() => import('components/common/RelativeDate'), { ssr: false });

type Props = {
  user: Pick<
    User,
    | 'id'
    | 'role'
    | 'isVerified'
    | 'isEmployee'
    | 'isFriends'
    | 'username'
    | 'level'
    | 'pictureUrl'
    | 'haveISentFriendRequest'
    | 'haveIReceivedFriendRequest'
  >;
  friendSince?: Date;
  followingSince?: Date;
  followerSince?: Date;
  hideCheck?: boolean;
  hideFriendRequestButton?: boolean;
  showOnlyViewRequest?: boolean;
};

export const UserCard = ({
  user,
  friendSince,
  followerSince,
  followingSince,
  hideCheck,
  hideFriendRequestButton,
  showOnlyViewRequest,
}: Props) => {
  const {
    username,
    pictureUrl,
    level,
    isVerified,
    isEmployee,
    haveISentFriendRequest,
    haveIReceivedFriendRequest,
    isFriends,
  } = user;
  const theme = useTheme();

  const cancelFriendRequest = useCancelFriendRequest();
  const sendFriendRequest = useSendFriendRequest();
  const acceptFriendRequest = useAcceptFriendRequest();
  const { me, isLoggedIn } = useMe();
  const isMe = me?.id === user.id;
  const showFriendRequestButton = !isMe && !isFriends && !hideFriendRequestButton;

  const handleFriendRequest = (action: FriendRequestAction, friendRequest: FriendRequestPayload) => {
    switch (action) {
      case FriendRequestAction.SEND_REQUEST:
        sendFriendRequest(friendRequest);
        break;
      case FriendRequestAction.CANCEL_REQUEST:
        cancelFriendRequest(friendRequest);
        break;

      case FriendRequestAction.ACCEPT_REQUEST:
        acceptFriendRequest(user);
        break;

      default:
        console.error('No FriendRequestAction specified in handleFriendRequest()');
        break;
    }
  };
  return (
    <Link className="cursor-pointer" href={`/user/${username}`} passHref legacyBehavior prefetch={false}>
      <div className="aspect-square  bg-greyscale-almostBlack rounded-lg cursor-pointer" data-testid="user-card-link">
        <div className="flex justify-center h-[25%] items-center px-2 mediumLarge:px-4 box-content">
          <div className="text-p_medium flex flex-shrink min-w-0">
            <span className="text-ellipsis whitespace-nowrap overflow-hidden">{username}</span>
          </div>
          {(isEmployee || isVerified) && (
            <div className="pl-1">
              <VerifiedBadge isEmployee={isEmployee} isVerified={isVerified} size={18} />
            </div>
          )}
        </div>
        <div className="h-[50%] w-[50%] m-auto">
          <div className="flex relative flex-col">
            <img
              className="rounded-full w-full inline-block m-auto"
              src={pictureUrl || ''}
              title={`${username} profile image`}
            />
            <div className="bg-accents-purple-primary text-greyscale-white text-button_small rounded-full w-6 h-6 font-semibold flex items-center justify-center absolute bottom-1 right-1">
              <span aria-label="user-level">{level}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col text-center justify-center items-center h-[30%]">
          {isFriends && !hideCheck && (
            <div className="flex items-center h-11">
              <p className="text-accents-green-primary text-label_xlarge m-0">Friends</p>
              <Spacer width={4} />
              <Checkmark title="Check" color={theme.primaryColor.green} size={20} />
            </div>
          )}
          {friendSince && (
            <div className="text-greyscale-mediumGrey text-label_specialSmall">
              Friends since&nbsp;
              <RelativeDate value={friendSince} />
            </div>
          )}
          {followingSince && (
            <div className="text-greyscale-mediumGrey text-label_specialSmall">
              Followed since&nbsp;
              <RelativeDate value={followingSince} />
            </div>
          )}
          {followerSince && (
            <div className="text-greyscale-mediumGrey text-label_specialSmall">
              Follower since&nbsp;
              <RelativeDate value={followerSince} />
            </div>
          )}
          {showFriendRequestButton && isLoggedIn && (
            <div className="flex justify-center items-center">
              {showOnlyViewRequest && Boolean(haveIReceivedFriendRequest) ? (
                <Button variety="primary" size="small" text="View Request" Icon={<Friends size={20} />} />
              ) : (
                <>
                  <FriendRequestButton
                    haveISentFriendRequest={Boolean(haveISentFriendRequest)}
                    haveIReceivedFriendRequest={Boolean(haveIReceivedFriendRequest)}
                    friendRequest={user}
                    onClick={handleFriendRequest}
                  />
                  {haveIReceivedFriendRequest && (
                    <FriendRequestButton
                      haveISentFriendRequest={false}
                      haveIReceivedFriendRequest
                      friendRequest={user}
                      declineFriendRequest
                      onClick={handleFriendRequest}
                      simpleDecline
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default UserCard;
