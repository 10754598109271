import { Checkmark, Favorite, FavoriteOutline, Minus } from '@hiberworld/icons';
import cn from 'classnames';
import { Button } from 'components/common/buttons/Button';
import { useState } from 'react';
import * as S from './FriendButton.styles';
import { FriendButtonProps } from './FriendButton.types';

export const FriendButton = ({
  onClick,
  disableFeatureFriend,
  haveIFeaturedFriend,
  onFeatureClick,
  amountOfFeaturedFriends,
  maxAmountOfFeaturedFriends,
}: FriendButtonProps) => {
  const [isHoveringFriendsButton, setIsHoveringFriendsButton] = useState(false);

  const text = isHoveringFriendsButton ? 'Remove' : 'Friends';

  const haveReachedMaxAmount = maxAmountOfFeaturedFriends && amountOfFeaturedFriends >= maxAmountOfFeaturedFriends;

  const handleMouseEnter = () => {
    setIsHoveringFriendsButton(true);
  };

  const handleMouseLeave = () => {
    setIsHoveringFriendsButton(false);
  };

  const getDesktopTooltipText = () => {
    if (haveIFeaturedFriend) {
      return 'Remove from Featured Friends';
    }

    if (haveReachedMaxAmount) {
      return `Can't add as featured friend. You can have max ${maxAmountOfFeaturedFriends}`;
    }

    return 'Add to Featured Friends';
  };

  const getMobileTooltipText = () => {
    if (!haveIFeaturedFriend) {
      return 'Removed from Featured Friends';
    }

    if (haveReachedMaxAmount) {
      return `Can't add as featured friend. You can have max ${maxAmountOfFeaturedFriends}`;
    }
    return 'Added to Featured Friends';
  };

  return (
    <S.FriendButtonWrapper>
      <Button
        variety="primary"
        size="medium"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
        text={text}
        Icon={isHoveringFriendsButton ? <Minus size={20} /> : <Checkmark size={20} />}
      />

      {!disableFeatureFriend && (
        <S.FeatureFriendButton
          onClick={!haveReachedMaxAmount ? onFeatureClick : undefined}
          className={cn({ isHoveringFriendsButton, haveIFeaturedFriend, haveReachedMaxAmount })}>
          {haveIFeaturedFriend ? <Favorite size={20} /> : <FavoriteOutline size={20} />}
          <S.LabelWrapper size={24}>
            <S.Label>{getDesktopTooltipText()}</S.Label>
            <S.MobileLabel>{getMobileTooltipText()}</S.MobileLabel>
          </S.LabelWrapper>
        </S.FeatureFriendButton>
      )}
    </S.FriendButtonWrapper>
  );
};
