/* eslint-disable camelcase */
import { useApolloClient } from '@apollo/client';
import { DEFAULT_TITLE, Head } from 'components/common/Head';
import { PageContentWrapper } from 'components/layouts/PageContentWrapper/PageContentWrapper';
import { Spacer } from 'components/layouts/Spacer';
import { useDive } from 'hooks/useDive';
import { useMe } from 'hooks/useMe';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { isClientSide } from 'utils/isClientSide';
import { MainLayoutContainer } from './MainLayout.styles';
import { MainLayoutProps } from './MainLayout.types';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import { FEATURE_TOGGLE_SIGN_UP_REWARD_PROMPT } from 'constants/featureToggle.constants';
import { useIsEmbeddedInEngine } from 'lib/useIsEmbeddedInEngine';

const SignUpWithRewardContainer = dynamic(
  () => import('containers/SignUpWithRewardContainer').then(module => module.SignUpWithRewardContainer),
  {
    ssr: false,
  }
);

const useResetBFCache = () => {
  const { me } = useMe();
  const { id: currentUserId } = me ?? {};
  const store = useApolloClient();

  useEffect(() => {
    const resetCache = (e: PageTransitionEvent) => {
      if (e.persisted && !currentUserId) {
        store.cache.reset();
      }
    };
    if (isClientSide()) {
      window.addEventListener('pageshow', resetCache);
    }

    return () => {
      if (isClientSide()) {
        window.removeEventListener('pageshow', resetCache);
      }
    };
  }, [currentUserId, store]);
};

const useTrackUserPageOpened = () => {
  const { trackUserPageOpened } = useDive();
  useEffect(() => {
    trackUserPageOpened();
  }, [trackUserPageOpened]);
};

export const MainLayout = ({
  children,
  title = DEFAULT_TITLE,
  fullWidth,
  noHorizontalPadding,
  noBottomMargin,
}: MainLayoutProps) => {
  useTrackUserPageOpened();
  useResetBFCache();

  const { isEmbeddedInEngine } = useIsEmbeddedInEngine();
  const inEngine = isEmbeddedInEngine();

  const signUpRewardEnabled = useFeatureToggle(FEATURE_TOGGLE_SIGN_UP_REWARD_PROMPT).isEnabled;

  return (
    <MainLayoutContainer>
      <Head title={title} />
      <PageContentWrapper fullWidth={fullWidth} noHorizontalPadding={noHorizontalPadding}>
        {children}
        {!noBottomMargin && <Spacer height={80} />}
      </PageContentWrapper>

      {!inEngine && signUpRewardEnabled && <SignUpWithRewardContainer />}

      <div id="modal-root" />
    </MainLayoutContainer>
  );
};
