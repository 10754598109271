import { FriendRequestPayload } from 'hooks/friends/types/friendRequestPayload';

export enum FriendRequestAction {
  SEND_REQUEST = 'add-friend',
  ACCEPT_REQUEST = 'accept',
  CANCEL_REQUEST = 'request-sent',
}

export type FriendRequestButtonProps = {
  onClick: (action: FriendRequestAction, friendRequest?: FriendRequestPayload) => void;
  haveISentFriendRequest: boolean | undefined | null;
  haveIReceivedFriendRequest: boolean | undefined | null;
  declineFriendRequest?: boolean;
  friendRequest?: FriendRequestPayload;
  iconLeft?: JSX.Element;
  simpleDecline?: boolean;
};

export type FriendButtonProps = {
  disableFeatureFriend?: boolean | undefined;
  haveIFeaturedFriend?: boolean | undefined;
  amountOfFeaturedFriends: number;
  maxAmountOfFeaturedFriends: number;
  onFeatureClick: () => void;
  onClick: () => void;
};

export type LabelWrapperStyleProps = { size: number };
