import { Add, Checkmark, Close, Minus, Send } from '@hiberworld/icons';
import { Button } from 'components/common/buttons/Button';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { FriendRequestAction, FriendRequestButtonProps } from './FriendButton.types';

export const FriendRequestButton = ({
  haveISentFriendRequest,
  haveIReceivedFriendRequest,
  friendRequest,
  declineFriendRequest,
  simpleDecline,
  onClick,
}: FriendRequestButtonProps) => {
  const [isHoveringFriendRequestButton, setIsHoveringFriendRequestButton] = useState(false);
  const theme = useTheme();

  const getTextFromStatus = () => {
    if (haveISentFriendRequest && isHoveringFriendRequestButton) {
      return 'Unsend Request';
    }
    if (haveIReceivedFriendRequest) {
      return !haveISentFriendRequest && declineFriendRequest ? '' : 'Accept';
    }

    if (haveISentFriendRequest) {
      return 'Request Sent';
    }

    return 'Add Friend';
  };

  const getActionFromStatus = () => {
    if (haveIReceivedFriendRequest) {
      return !haveISentFriendRequest && declineFriendRequest
        ? FriendRequestAction.CANCEL_REQUEST
        : FriendRequestAction.ACCEPT_REQUEST;
    }

    if (haveISentFriendRequest) {
      return FriendRequestAction.CANCEL_REQUEST;
    }
    if (haveIReceivedFriendRequest && declineFriendRequest) {
      return FriendRequestAction.CANCEL_REQUEST;
    }

    return FriendRequestAction.SEND_REQUEST;
  };

  const getIconFromStatus = () => {
    if (haveISentFriendRequest && isHoveringFriendRequestButton) {
      return <Minus />;
    }

    if (haveIReceivedFriendRequest) {
      return !haveISentFriendRequest && declineFriendRequest ? <Close /> : <Checkmark />;
    }

    if (haveISentFriendRequest) {
      return <Send color={theme.primaryColor.white} />;
    }
    if (haveIReceivedFriendRequest && declineFriendRequest) {
      return <Minus />;
    }

    return <Add />;
  };

  const handleMouseEnter = () => {
    setIsHoveringFriendRequestButton(true);
  };

  const handleMouseLeave = () => {
    setIsHoveringFriendRequestButton(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    onClick(getActionFromStatus(), friendRequest);
    setIsHoveringFriendRequestButton(false);
  };

  if (simpleDecline) {
    return (
      <Button
        variety="text"
        size="medium"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        Icon={getIconFromStatus()}
        text={getTextFromStatus()}
      />
    );
  }

  return (
    <Button
      size="small"
      variety={haveISentFriendRequest ? 'secondary' : 'primary'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      Icon={getIconFromStatus()}
      text={getTextFromStatus()}
    />
  );
};
